import Image from 'next/image';
import styled from 'styled-components';
import media from 'utils/styles/media.styles';
import { setVh } from 'utils/styles/mixins';

export const Main = styled.main`
  min-height: ${setVh(100)};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 2rem 2rem 10rem;

  ${media.desktop} {
    padding: 4rem;
  }
`;

export const Code = styled.code`
  font-weight: 700;
`;

export const Grid = styled.div`
  max-width: 320px;

  display: grid;
  grid-template-columns: 1fr;

  margin-bottom: 120px;
  text-align: center;

  ${media.tablet} {
    grid-template-columns: repeat(2, 50%);
  }

  ${media.desktop} {
    max-width: 100%;

    grid-template-columns: repeat(4, minmax(25%, auto));

    margin: 0;
    text-align: left;
  }
`;

export const Card = styled.a`
  padding: 1rem 1.2rem;

  border-radius: 12px;
  background: rgba(100, 100, 100, 0);
  border: 1px solid rgba(108, 108, 108, 0);

  transition: background 200ms, border 200ms;

  span {
    display: inline-block;
    transition: transform 200ms;
  }

  h4 {
    margin-bottom: 1rem;
  }

  p {
    max-width: 30ch;
    opacity: 0.6;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: rgba(100, 100, 100, 0.1);
      border: 1px solid rgba(108, 108, 108, 0.15);
    }

    &:hover span {
      transform: translateX(4px);
    }
  }
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  padding: 6rem 0;

  &::before,
  &::after {
    content: '';
    position: absolute;

    left: 50%;

    filter: blur(45px);
    transform: translateZ(0);
  }

  &::before {
    width: 480px;
    height: 360px;
    background: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    border-radius: 50%;
    margin-left: -400px;
  }

  &::after {
    width: 240px;
    height: 180px;
    background: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));

    z-index: -1;
  }
`;

export const VercelLogo = styled(Image)`
  filter: invert(1);
`;

export const Logo = styled(Image)`
  position: relative;
  filter: invert(1) drop-shadow(0 0 0.3rem #ffffff70);
`;
