export const CARDS_DATA_TEMPLATE = [
  {
    href: '/',
    title: 'Aplikacje ',
    desc: 'Twoja firma prężnie się rozwija, ale nie Twoje procesy są zbyt długie i czasochłonne? Skontaktuj się z nami, a zrealizujemy dowolną aplikację webową.',
  },
  {
    href: '/',
    title: 'Strony ',
    desc: 'Potrzebujesz strony dla Twojej firmy? Albo potrzebujesz ją odświeżyć? Jesteśmy w stanie wykonać każdy projekt!',
  },
  {
    href: '/',
    title: 'Analiza danych ',
    desc: 'Okryj nowe możliwości analizy twoich danych w każdym możliwym kierunku.',
  },
  {
    href: '/',
    title: 'Web scraping ',
    desc: 'Pozyskiwanie danych z różnych stron poprzez "scraping", czyli wyciąganie danych stron automatycznie poprzez skrypty.',
  },
];
