import { FunctionComponent } from 'react';
import { GetStaticProps } from 'next';
import { ISR_TIMEOUT } from 'utils/config';

import { CARDS_DATA_TEMPLATE } from './IndexPage.data';
import * as S from './IndexPage.styles';

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
    revalidate: ISR_TIMEOUT,
  };
};

interface IndexPageProps {}

const IndexPage: FunctionComponent<IndexPageProps> = () => {
  return (
    <S.Main>
      <S.Center>
        <S.Logo
          src="/antadaa.png"
          alt="Antadaa Logo"
          width={380}
          height={380}
          priority
        />
      </S.Center>

      <S.Grid>
        {CARDS_DATA_TEMPLATE.map(({ href, title, desc }, idx) => (
          <S.Card
            key={title + idx}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h4>
              {title} <span>-&gt;</span>
            </h4>
            <p>{desc}</p>
          </S.Card>
        ))}
      </S.Grid>
    </S.Main>
  );
};

export default IndexPage;
